import { FC } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { OrderDto } from 'ventas-digitales-v2-common';
import OrderStatusChip from '../OrderStatusChip';

const OrderStatusRenderer: FC<CustomCellRendererProps<OrderDto>> = ({
  data,
}) => {
  if (!data?.status) {
    return '-';
  }

  return <OrderStatusChip status={data.status} />;
};

export default OrderStatusRenderer;
