import { FC } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { OrderStatus } from 'ventas-digitales-v2-common';
import { Box, Grid } from '@mui/material';
import { STATUS_BG_COLORS } from '../OrderStatusChip';

const OrderStatusFilterRenderer: FC<
  CustomCellRendererProps<unknown, { id: OrderStatus; label: string }>
> = ({ value }) => {
  const { id, label } = value || {};

  if (!id) {
    return 'Seleccionar todo';
  }

  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 8,
            width: 8,
            borderRadius: 1,
            backgroundColor: STATUS_BG_COLORS[id],
          }}
        />
      </Grid>
      <Grid item>{label}</Grid>
    </Grid>
  );
};

export default OrderStatusFilterRenderer;
