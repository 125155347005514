import { FC } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import Typography from '@mui/material/Typography';
import { shortUUID } from '../../utils/uuid';

const UUIDRenderer: FC<CustomCellRendererProps> = ({ value }) => {
  return (
    <Typography variant="overline" fontWeight="bold">
      {shortUUID(value)}
    </Typography>
  );
};

export default UUIDRenderer;
