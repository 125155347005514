import type { NextPage } from 'next';
import Grid from '@mui/material/Grid';
import useUser from '../auth/useUser';
import { Layout } from 'ui';
import OrdersTable from '../components/OrdersTable';
import { useMemo } from 'react';

const Home: NextPage = () => {
  const user = useUser();

  const operatingUnitsLabel = useMemo(() => {
    const operatingUnits = user?.appMeta?.config?.operatingUnits as string[];

    if (!operatingUnits?.length) {
      return null;
    }

    return ` • ${operatingUnits.join(' | ')}`;
  }, [user]);

  return (
    <Layout
      pageTitle="Ventas Digitales"
      userName={user?.name}
      userLogout={user?.actions?.logout}
    >
      <Grid container>
        <Grid item xs={12}>
          <OrdersTable operatingUnitsLabel={operatingUnitsLabel} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;
